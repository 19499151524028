<template>
  <el-main>
    <el-form :model="ruleForm" class="el-form-search" label-width="130px">
      <el-form-item label="用户昵称：">
        <el-input size="small" v-model="ruleForm.keyword" placeholder="请输入回收员信息"></el-input>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader size="small" v-model="ruleForm.area_id" :props="{ value: 'id', label: 'name', children: '_child' }" placeholder="请选择或搜索" filterable :options="areaList"></el-cascader>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker v-model="ruleForm.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="用户信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :name="row.nickname" :avatar="row.avatar"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column label="所属区域" align="center">
        <template v-slot="{ row }">
          {{ row.province + row.city + row.area }}
        </template>
      </el-table-column>
      <el-table-column label="累计金额" align="center">
        <template v-slot="{ row }">￥{{ Number(row.total_order_amount).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column prop="total_order_count" label="累计订单数" align="center">
        <template v-slot="{ row }">{{ row.total_order_count }}</template>
      </el-table-column>
      <el-table-column prop="energy_value" label="累计公益值" align="center"></el-table-column>
      <el-table-column label="注册时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="lookDetail(row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import UserInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate.js';
export default {
  components: {
    UserInfo,
    Paging,
  },
  data() {
    return {
      getDateformat,
      ruleForm: {
        keyword: '',
        area_id: [],
        time: [],
      },
      areaList: [],
      page: 1,
      rows: 10,
      total: 0,
      dataList: [],
    };
  },
  created() {
    if (this.$route.query.cityId) {
      this.ruleForm.area_id = JSON.parse(this.$route.query.cityId);
    }
    this.getDataList();
    this.getArea();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getDataList();
    },
    clearSearch() {
      this.ruleForm = {
        keyword: '',
        area_id: [],
        time: [],
      };
      this.getDataList(1);
    },
    getDataList(style) {
      if (style) {
        this.page = 1;
      }
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.ruleForm.keyword) {
        obj.keyword = this.ruleForm.keyword;
      }
      if (this.ruleForm.time && this.ruleForm.time.length) {
        obj.create_time = [Math.floor(this.ruleForm.time[0] / 1000), Math.floor(this.ruleForm.time[1] / 1000)];
        if (obj.create_time[0] === obj.create_time[1]) {
          obj.create_time[1] = obj.create_time[0] + 60 * 60 * 24 - 1;
        }
      }
      if (this.ruleForm.area_id.length) {
        obj.area_id = this.ruleForm.area_id[2];
      }
      this.$axios.post(this.$api.O2O.recycler.userList, obj).then(res => {
        if (res.code === 0) {
          this.dataList = res.result.list;
          this.total = res.result.total;
        }
      });
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
    lookDetail(row) {
      this.$router.push({
        path: '/user/O2OuserDetail',
        query: { id: row.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
