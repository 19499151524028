var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-form',{staticClass:"el-form-search",attrs:{"model":_vm.ruleForm,"label-width":"130px"}},[_c('el-form-item',{attrs:{"label":"用户昵称："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入回收员信息"},model:{value:(_vm.ruleForm.keyword),callback:function ($$v) {_vm.$set(_vm.ruleForm, "keyword", $$v)},expression:"ruleForm.keyword"}})],1),_c('el-form-item',{attrs:{"label":"所属区域："}},[_c('el-cascader',{attrs:{"size":"small","props":{ value: 'id', label: 'name', children: '_child' },"placeholder":"请选择或搜索","filterable":"","options":_vm.areaList},model:{value:(_vm.ruleForm.area_id),callback:function ($$v) {_vm.$set(_vm.ruleForm, "area_id", $$v)},expression:"ruleForm.area_id"}})],1),_c('el-form-item',{attrs:{"label":"注册日期："}},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","size":"small"},model:{value:(_vm.ruleForm.time),callback:function ($$v) {_vm.$set(_vm.ruleForm, "time", $$v)},expression:"ruleForm.time"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"30px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.getDataList(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.clearSearch}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.dataList,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"label":"用户信息","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('UserInfo',{attrs:{"name":row.nickname,"avatar":row.avatar}})]}}])}),_c('el-table-column',{attrs:{"label":"所属区域","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.province + row.city + row.area)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"累计金额","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("￥"+_vm._s(Number(row.total_order_amount).toFixed(2)))]}}])}),_c('el-table-column',{attrs:{"prop":"total_order_count","label":"累计订单数","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.total_order_count))]}}])}),_c('el-table-column',{attrs:{"prop":"energy_value","label":"累计公益值","align":"center"}}),_c('el-table-column',{attrs:{"label":"注册时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.getDateformat(row.create_time)))]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.lookDetail(row)}}},[_vm._v("详情")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total,"page":_vm.page,"pageNum":_vm.rows},on:{"updatePageNum":_vm.updateData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }